import environment from "config/environment"

export function serializeModule(module) {
  environment.config.maxPointsQuestionModule
  const quantityOfQuestions = module.amount_of_questions
    ? module.amount_of_questions
    : 4;

  const totalMaxPoints = quantityOfQuestions * environment.config.maxPointsQuestionModule

  return {
    ...module,
    stars: 5 * module.result / totalMaxPoints
  }
}

export function serializeTechnicalModules(modules) {
  return modules.map(serializeModule)
}
