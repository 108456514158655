const JUSTIFICATIONS = {
  forgetfulness: 'Esqueceu de bater o ponto',
  medical_certificate: 'Atestado médico',
  late: 'Atraso',
  others: 'Outros',
  justified_absence: 'Falta justificada',
  home_office: 'Home office',
  external_work: 'Trabalho externo',
}

export {
  JUSTIFICATIONS,
}
