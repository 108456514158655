import { DateTime } from 'luxon'
import moment from 'moment'

moment.locale('pt-br')

export const TYPES_CALENDAR = {
  CLT: 'clt',
  CLT_FLEX: 'clt_flex',
  FREELANCER: 'freelancer',
}

export function serializeEventDetail(event) {
  let titleFullDescription = ''
  let eventColor = ''

  const isFreelancerEvent = event.opportunity?.type_opportunity === TYPES_CALENDAR.FREELANCER
  const isInterviewEvent = !event.candidates.length && (
    [TYPES_CALENDAR.CLT, TYPES_CALENDAR.CLT_FLEX].includes(event.opportunity?.type_opportunity)
  )
  const isInterviewScheduledEvent = event.candidates.length && (
    [TYPES_CALENDAR.CLT, TYPES_CALENDAR.CLT_FLEX].includes(event.opportunity?.type_opportunity)
  )

  if (isFreelancerEvent) {
    titleFullDescription = `Freelancer - ${event.opportunity?.position?.name || '(Sem posição)'}`
    eventColor = '#72b920'
  }

  if (isInterviewEvent) {
    titleFullDescription = 'Entrevista'
    eventColor = '#41C0F0'
  }

  if (isInterviewScheduledEvent) {
    titleFullDescription = `Entrevista - ${event.candidates[0]?.name || '(Sem candidato)'}`
    eventColor = '#eb8126'
  }

  const hour = Math.abs(DateTime.fromISO(event.start_at).offset / 60)

  const startAt = DateTime
    .fromISO(event.start_at)
  const endAt = DateTime
    .fromISO(event.end_at)

  const timeFullDescription = `${startAt.toFormat('DDDD')} • ${startAt.toFormat('HH:mm')} até ${endAt.toFormat('HH:mm')}`

  if (event.opportunity?.salary) {
    event.opportunity.salary = parseFloat(event.opportunity?.salary)
  }

  return {
    ...event,
    start_at: startAt.toISO(),
    end_at: endAt.toISO(),
    timeFullDescription,
    titleFullDescription,
    eventColor,
  }
}

export function serializeEvent(event) {
  let title = ''
  let classEvent = ''
  let type = ''

  const isFreelancerEvent = event.type_calendar === TYPES_CALENDAR.FREELANCER
  const isInterviewEvent = !event.candidates.length && (
    [TYPES_CALENDAR.CLT, TYPES_CALENDAR.CLT_FLEX].includes(event.type_calendar)
  )
  const isInterviewScheduledEvent = event.candidates.length && (
    [TYPES_CALENDAR.CLT, TYPES_CALENDAR.CLT_FLEX].includes(event.type_calendar)
  )

  if (isFreelancerEvent) {
    title = event.opportunity?.position ? `${event.opportunity?.position}` : '(Não informado)'
    classEvent = `Event__freelancer address_${event.address}`
    type = 'Freelancer'
  }

  if (isInterviewEvent) {
    title = 'Entrevista'
    classEvent = `Event__interview address_${event.address}`
    type = 'Entrevista'
  }

  if (isInterviewScheduledEvent) {
    title = event.candidates[0]?.name ? `${event.candidates[0]?.name}` : 'Sem o nome do candidato'
    classEvent = 'Event__scheduled-interview'
    type = 'Entrevista'
  }

  const hour = Math.abs(DateTime.fromISO(event.start_at).offset / 60)

  const startAt = DateTime
    .fromISO(event.start_at)
  const endAt = DateTime
    .fromISO(event.end_at)

  return {
    ...event,
    title,
    type,
    classes: classEvent,
    start: startAt.toJSDate(),
    end: endAt.toJSDate(),
  }
}

export function serializerInterview(interviews) {
  const serializedInterviews = []
  interviews.forEach((interview) => {
    interview.candidates.forEach((candidate) => {
      serializedInterviews.push(
        {
          id: interview.pk,
          end_date: interview.end_at,
          start_date: interview.start_at,
          type_calendar: interview.type_calendar,
          candidate: {
            name: candidate.name,
            id: candidate.pk,
            avatar: candidate.avatar,
            positions: candidate.positions,
            years_old: candidate.years_old,
          },
          opportunity: {
            id: interview.opportunity.pk,
            position: interview.opportunity.position,
            name: interview.opportunity.name,
            type_opportunity: interview.opportunity.type_opportunity,
            subsidiary: {
              id: interview.opportunity.subsidiary,
            },
            address: interview.address,
          },
        },
      )
    })
  })
  return serializedInterviews
}
