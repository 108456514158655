import { DateTime } from 'luxon'

const serializeExperience = (experience) => ({
  ...experience,
  period_finished: DateTime.fromISO(experience.period_start).toFormat('dd/MM/yyyy'),
  period_start: DateTime.fromISO(experience.period_start).toFormat('dd/MM/yyyy'),
})

export function serializeCandidate(people) {
  const age = Math.floor(DateTime.now().diff(DateTime.fromISO(people.birth_date), ['years']).toObject().years)

  const whatsAppUrl = (people.phone)
    ? `https://api.whatsapp.com/send?1=pt_BR&phone=${people.phone}`
    : null

  const candidateDetailUrl = `${window.location.origin}/candidato/${people.id}`

  return {
    ...people,
    age,
    whatsAppUrl,
    candidateDetailUrl,
    experiences: people.experiences_list.map(serializeExperience),
  }
}
