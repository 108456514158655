import api from "../../providers/api";

async function getLovedHeart(companyId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/love-people/?company=${companyId}/`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error
      })
    }
  })
}

async function setLoved(isLoved, companyId, peopleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(`/api/v1/love-people/`, {
        "people": peopleId,
        "company": companyId,
        "loved": isLoved
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as vagas',
        detail: error
      })
    }
  })
}

export default {
  setLoved,
  getLovedHeart
}
