import moment from 'moment'
import { extractHours } from 'containers/beat-time/components/TimeTrackingTable/utils/momentToBr'
import { JUSTIFICATIONS } from 'containers/beat-time/utils/justifications'
import { DateTime } from 'luxon'

function convertTimeStringInSeconds(timeString) {

  if (!timeString) return 0

  const [hours, minutes, seconds] = timeString.split(':')
  const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds)
  return totalSeconds
}

function serializeTimeTrackingReport(payload) {
  const report = payload.report.map(timeTracking => ({
    ...timeTracking,
    date_title: moment(timeTracking.time_start).format('DD/MM/YYYY'),
    day: moment(timeTracking.time_start).format('DD/MM'),
    worked: timeTracking.type !== 'dayoff' ? timeTracking.worked_hours || 'FALTA' : 'FOLGA',
    worked_hours_seconds: convertTimeStringInSeconds(timeTracking.worked_hours),
    extra_seconds: convertTimeStringInSeconds(timeTracking.extra),
    absent_seconds: convertTimeStringInSeconds(timeTracking.absent),
  }))

  const workedData = report.slice(-7).map(({ worked_hours_seconds, worked, day }, index) => ({
    x: index + 1,
    day_title: day,
    y: Number(worked_hours_seconds),
    fill: '#62EBB1',
  }))
  const extraData = report.slice(-7).map(({ extra_seconds, extra, day }, index) => ({
    x: index + 1,
    day_title: day,
    y: Number(extra_seconds),
    fill: '#707070',
  }))
  const absentData = report.slice(-7).map(({ absent_seconds, extra, day }, index) => ({
    x: index + 1,
    day_title: day,
    y: Number(absent_seconds),
    fill: '#41C0F0',
  }))

  return {
    report,
    resume: {
      absent: payload.resume?.absent?.substring(0, payload.resume.absent.length - 2) || '00:00',
      extra: payload.resume?.extra?.substring(0, payload.resume.extra.length - 2) || '00:00',
      worked: payload.resume?.worked?.substring(0, payload.resume.worked.length - 2) || '00:00',
      faults: payload.resume?.faults || 0,
      chart: [
        workedData,
        extraData,
        absentData,
      ],
    },
  }
}

function serializeScaleTime(time, type) {
  const isMonthly = type === 'monthly'

  return {
    dayOff: time.day_off,
    day: isMonthly ? time.weekday : moment(time.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    input_1: time.input_1 || '',
    output_1: time.output_1 || '',
    input_2: time.input_2 || '',
    output_2: time.output_2 || '',
    input_3: time.input_3 || '',
    output_3: time.output_3 || '',
  }
}

function serializeScale(scale) {
  return {
    ...scale,
    free_interval_time: scale?.free_interval_time
      ? scale.free_interval_time.substring(0, 5) || ''
      : '',
    times: scale.times.map((time) => ({
      ...serializeScaleTime(time, scale.scale_type),
    })),
  }
}

function serializeScales(scales, employees = []) {
  const events = []
  scales.map((scaleDays) => {
    scaleDays.scale_per_employee.map((scaleEmployee) => {
      const employee = {
        id: scaleEmployee.id,
        name: scaleEmployee.name.length > 10
          ? `${scaleEmployee.name.substr(0, 10)}...`
          : scaleEmployee.name,
      }

      scaleEmployee.scales.map((scale) => {
        const title = employee.name
        const classes = ''
        const day = scale.day || scaleDays.day

        if(scale.input_1 && scale.output_1) {
          events.push({
            title: `${title} ${scale.input_1.substr(0, 5)} - ${scale.output_1.substr(0, 5)}`,
            classes,
            start: DateTime.fromISO(`${day}T${scale.input_1}`).toJSDate(),
            end: DateTime.fromISO(`${day}T${scale.output_1}`).toJSDate(),
            color: employees.find(({ id }) => id === employee.id)?.color || '',
          })
        }

        if (scale.input_2 && scale.output_2) {
          events.push({
            title: `${title} ${scale.input_2.substr(0, 5)} - ${scale.output_2.substr(0, 5)}`,
            classes,
            start: DateTime.fromISO(`${day}T${scale.input_2}`).toJSDate(),
            end: DateTime.fromISO(`${day}T${scale.output_2}`).toJSDate(),
            color: employees.find(({ id }) => id === employee.id)?.color || '',
          })
        }

        if (scale.input_3 && scale.output_3) {
          events.push({
            title: `${title} ${scale.input_3.substr(0, 5)} - ${scale.output_3.substr(0, 5)}`,
            classes,
            start: DateTime.fromISO(`${day}T${scale.input_3}`).toJSDate(),
            end: DateTime.fromISO(`${day}T${scale.output_3}`).toJSDate(),
            color: employees.find(({ id }) => id === employee.id)?.color || '',
          })
        }
      })
    })
  })

  return events
}

function bindJustification(timeCard) {
  let justification = ''

  if (timeCard?.justification) {
    justification = JUSTIFICATIONS[timeCard.justification]
  }

  if (timeCard?.justification && timeCard?.justification === 'others') {
    justification = `${JUSTIFICATIONS[timeCard.justification]}: ${timeCard.reason_for_edit}`
  }

  return justification
}

function serializeTimeTracking(timeTracking) {
  return {
    day: timeTracking.date,
    dayOff: timeTracking.day_off,
    overtime: timeTracking.overtime,
    total_hours: timeTracking.total_hours,
    subsidiary: timeTracking.subsidiary,
    employee: timeTracking.employee,
    times: {
      input_1: {
        point_type: 'input_1',
        subsidiary: timeTracking.subsidiary,
        employee: timeTracking.employee,
        day: timeTracking.date,
        ...timeTracking.timecards[0],
        value: timeTracking.timecards[0]?.to_clock,
        justification: bindJustification(timeTracking.timecards[0]),
        hour: timeTracking.timecards[0]?.to_clock ? extractHours(timeTracking.timecards[0]?.to_clock, 'T') : '-',
      },
      output_1: {
        point_type: 'output_1',
        subsidiary: timeTracking.subsidiary,
        employee: timeTracking.employee,
        day: timeTracking.date,
        ...timeTracking.timecards[1],
        value: timeTracking.timecards[1]?.to_clock,
        justification: bindJustification(timeTracking.timecards[1]),
        hour: timeTracking.timecards[1]?.to_clock ? extractHours(timeTracking.timecards[1]?.to_clock, 'T') : '-',
      },
      input_2: {
        point_type: 'input_2',
        subsidiary: timeTracking.subsidiary,
        employee: timeTracking.employee,
        day: timeTracking.date,
        ...timeTracking.timecards[2],
        value: timeTracking.timecards[2]?.to_clock,
        justification: bindJustification(timeTracking.timecards[2]),
        hour: timeTracking.timecards[2]?.to_clock ? extractHours(timeTracking.timecards[2]?.to_clock, 'T') : '-',
      },
      output_2: {
        point_type: 'output_2',
        subsidiary: timeTracking.subsidiary,
        employee: timeTracking.employee,
        day: timeTracking.date,
        ...timeTracking.timecards[3],
        value: timeTracking.timecards[3]?.to_clock,
        justification: bindJustification(timeTracking.timecards[3]),
        hour: timeTracking.timecards[3]?.to_clock ? extractHours(timeTracking.timecards[3]?.to_clock, 'T') : '-',
      },
      input_3: {
        point_type: 'input_3',
        subsidiary: timeTracking.subsidiary,
        employee: timeTracking.employee,
        day: timeTracking.date,
        ...timeTracking.timecards[4],
        value: timeTracking.timecards[4]?.to_clock,
        justification: bindJustification(timeTracking.timecards[4]),
        hour: timeTracking.timecards[4]?.to_clock ? extractHours(timeTracking.timecards[4]?.to_clock, 'T') : '-',
      },
      output_3: {
        point_type: 'output_3',
        subsidiary: timeTracking.subsidiary,
        employee: timeTracking.employee,
        day: timeTracking.date,
        ...timeTracking.timecards[5],
        value: timeTracking.timecards[5]?.to_clock,
        justification: bindJustification(timeTracking.timecards[5]),
        hour: timeTracking.timecards[5]?.to_clock ? extractHours(timeTracking.timecards[5]?.to_clock, 'T') : '-',
      },
    },
  }
}

function serializeTimeTrackings(timeTrackings) {
  return timeTrackings?.map(serializeTimeTracking) || []
}

export {
  serializeScale,
  serializeScales,
  serializeTimeTrackingReport,
  serializeTimeTrackings,
  serializeTimeTracking,
}
