import api from "../../providers/api"
import { profileSerializer } from "./serializer"

async function find() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get('api/v1/profile/')
      resolve(profileSerializer(data))
    } catch (error) {
      reject({
        message: 'Não possível recuperar os dados do usuário logado',
        detail: error
      })
    }
  })
}

export default {
  find
}
