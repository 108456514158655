import api from 'providers/api'

/**
 * Get details of course
 * @param {number} params.category
 * @returns object
 */
function list(params) {
  return new Promise(
    (resolve, reject) => api
      .get('/api/v1/course/', {params})
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar os cursos'
        reject(error)
      }),
  )
}

/**
 * Get details of course
 * @param {uuid} course
 * @returns object
 */
function find(course) {
  return new Promise(
    (resolve, reject) => api
      .get(`/api/v1/course/${course}/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível pegar os detalhes do curso'
        reject(error)
      }),
  )
}

/**
 * Get details of course
 * @param {id} opportunity
 * @returns object
 */
function listByOpportunity(opportunity) {
  return new Promise(
    (resolve, reject) => api
      .get(`/api/v1/job-opportunity/job/${opportunity}/courses/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível pegar os detalhes do curso'
        reject(error)
      }),
  )
}

/**
 * Get details of course
 * @param {id} opportunity
 * @returns object
 */
function listByOpportunityForCandidate(opportunity) {
  return new Promise(
    (resolve, reject) => api
      .get(`/api/v1/job-opportunity/job/${opportunity}/tests/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível pegar os detalhes do curso'
        reject(error)
      }),
  )
}

/**
 * Start the course
 * @param {uuid} course
 * @returns object
 */
function start(course) {
  return new Promise(
    (resolve, reject) => api
      .post(`/api/v1/course/${course}/start/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Erro ao iniciar o curso'
        reject(error)
      }),
  )
}

/**
 * Start lesson of a course
 * @param {uuid} lesson
 * @returns object
 */
function startLesson(lesson) {
  return new Promise(
    (resolve, reject) => api
      .post(`/api/v1/course-lesson/${lesson}/start/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Erro ao iniciar o aula'
        reject(error)
      }),
  )
}

/**
 * Finish lesson of a course
 * @param {uuid} lesson
 * @returns object
 */
function finishLesson(lesson) {
  return new Promise(
    (resolve, reject) => api
      .post(`/api/v1/course-lesson/${lesson}/finish/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Erro ao iniciar o aula'
        reject(error)
      }),
  )
}

function categories() {
  return new Promise(
    (resolve, reject) => api
      .get('/api/v1/course-category/')
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar as categorias'
        reject(error)
      }),
  )
}

function report(employees, params) {
  return new Promise(async (resolve, reject) => {

    if (employees[0]?.id === '*') {
      employees = []
    }
    try {
      const ids = employees?.map(({id}) => id)?.join(',') || ''
      const paramsUrl = `?start_date=${params.start_date}&end_date=${params.end_date}&employees=${ids}`
      const paramsUrlAverage = `?start_date=${params.start_date}&end_date=${params.end_date}`

      const [
        {data: bigNumbers},
        {data: courses},
        {data: proofs},
        {data: timeline},
      ] = await Promise.all([
        api.get(`/api/v1/report-courses/${paramsUrl}`),
        api.get(`/api/v1/report-courses/courses/${paramsUrl}`),
        api.get(`/api/v1/report-courses/proofs/${paramsUrl}`),
        api.get(`/api/v1/report-courses/timeline/${paramsUrl}`),
      ])

      let average = null

      if (employees?.length === 1) {
        const [
          {data: averageBigNumbers},
          {data: averageCourses},
          {data: averageProofs},
          {data: averageTimeline},
        ] = await Promise.all([
          api.get(`/api/v1/report-courses/${paramsUrlAverage}`),
          api.get(`/api/v1/report-courses/courses/${paramsUrlAverage}`),
          api.get(`/api/v1/report-courses/proofs/${paramsUrlAverage}`),
          api.get(`/api/v1/report-courses/timeline/${paramsUrlAverage}`),
        ])

        average = {
          bigNumbers: averageBigNumbers,
          courses: averageCourses,
          proofs: averageProofs,
          timeline: averageTimeline,
        }
      }

      resolve({
        bigNumbers,
        courses,
        proofs,
        timeline,
        average,
      })
    } catch (e) {
      resolve({})
    }
  })
}

export default {
  list,
  find,
  listByOpportunity,
  listByOpportunityForCandidate,
  start,
  startLesson,
  finishLesson,
  categories,
  report,
}
