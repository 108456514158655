import api from '../../providers/api'

/**
 * Returns serialized employee data
 * @param employee Object
 *
 * @returns employee
 */
function serializeEmployee(employee) {
  return {
    ...employee,
  }
}

/**
 * Returns employees in company
 * @returns Promise<[employee]>
 */
async function getEmployees() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get('/api/v1/employee/?status=accepted&pagination=true')
      resolve(data)
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível cadastrar o candidato na vaga escolhida',
        detail: error,
      })
    }
  })
}

/**
 * Hired employees in company
 * @returns Promise<[employee]>
 */
async function hireEmployee(interviewId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post('/api/v1/employee/', payload)
      const { data: dataCalendar } = await api.patch(`/api/v1/calendar/${interviewId}/`, {
        status: 'approved',
        candidate_id: payload.people,
      })
      resolve(data)
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível cadastrar o employee na empresa',
        detail: error,
      })
    }
  })
}

/**
 * Candidate mark as absent in interview
 * @returns Promise<[employee]>
 */
async function absentCandidate(interviewId, candidateId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.patch(`/api/v1/calendar/${interviewId}/`, {
        status: 'absent',
        candidate_id: candidateId,
      })
      resolve(data)
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível cadastrar o employee na empresa',
        detail: error,
      })
    }
  })
}

/**
 * Candidate mark as absent in interview
 * @returns Promise<[employee]>
 */
async function rejectCandidate(interviewId, candidateId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.patch(`/api/v1/calendar/${interviewId}/`, {
        status: 'reproved',
        candidate_id: candidateId,
      })
      resolve(data)
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível cadastrar o employee na empresa',
        detail: error,
      })
    }
  })
}

export default {
  getEmployees,
  hireEmployee,
  absentCandidate,
  rejectCandidate,
}
