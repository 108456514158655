import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

export const DefaultLoadingContext = createContext({
  setDefaultLoading: () => {},
  defaultLoading: false,
})

export function DefaultLoadingProvider({ children }) {
  const [loading, setLoading] = useState(false)

  function setDefaultLoading(isLoading) {
    setTimeout(() => {
      setLoading(isLoading)
    }, isLoading ? 0 : 1000)
  }

  const value = {
    defaultLoading: loading,
    setDefaultLoading,
  }

  return (
    <DefaultLoadingContext.Provider value={value}>
      {children}
    </DefaultLoadingContext.Provider>
  )
}

DefaultLoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function useDefaultLoading() {
  const {
    defaultLoading,
    setDefaultLoading,
  } = useContext(DefaultLoadingContext)

  return {
    defaultLoading,
    setDefaultLoading,
  }
}
