import React, {
  createContext, useContext, useEffect, useState,
} from 'react'
import Cookies from 'js-cookie'
import Router, { useRouter } from 'next/router'

import { service } from 'services'
import api from 'providers/api'
import { useCompany } from 'hooks/useCompany'

export const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const [session, setSession] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { checkIfExistsPreOpportunityUuid } = useCompany()

  /**
     * @param {string} data.refresh - Token to revalidate authenticaded user
     * @param {string} data.access - Token to authenticate in api
     */

  async function validateSession(data) {
    setError(false)
    Cookies.set('token', data)

    api.defaults.headers.authorization = `Bearer ${data.access}`

    try {
      await api.post('/api/v1/token/verify/', { token: data.access })
    } catch (err) {
      setError({
        message: 'Falha na verificação do usuário',
        details: error.response?.data,
      })
    }

    let profile = null

    try {
      profile = await service.profile.find()
      setSession(profile)
      checkIfExistsPreOpportunityUuid()
    } catch (error) {
      if (error.response && error.response.data) {
        setError({
          message: 'Não foi possível fazer o login',
          details: error.response.data,
        })
      } else {
        setError({
          message: 'Não foi possível fazer o login',
          details: error.message,
        })
      }
    }

    if (!profile) {
      Router.push({
        pathname: '/entrar',
        query: {
          nextUrl: window.location.pathname,
        },
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line wrap-iife
    (async function () {
      if (Cookies.get('token')) {
        Cookies.remove('tempToken')
        const token = JSON.parse(Cookies.get('token'))
        await validateSession(token)
      }
    })()
  }, [])

  async function signIn(email, password) {
    setError(false)
    setLoading(true)

    try {
      const { data } = await api.post('/api/v1/token/', { email, password })

      if (data.access) {
        Cookies.remove('tempToken')
        Cookies.remove('in-beat-time')
        await validateSession(data)
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        return setError({
          message: `Desculpe, encontramos erros em seu login: ${error.response.data.detail}`,
          details: error.response.data,
        })
      }
      return setError({
        message: 'Desculpe! Encontramos problemas ao efetuar seu login',
        details: error.message,
      })
    }

    setLoading(false)
  }

  function singOut() {
    removeAuthCookies()
    Router.push('/entrar')
  }

  function singOutWithoutRedirect() {
    removeAuthCookies()
  }

  function removeAuthCookies() {
    Cookies.remove('token')
    Cookies.remove('impersonate')
    Cookies.remove('tempToken')
    Cookies.remove('candidate-slugged')
    setSession(null)
  }

  const value = {
    signIn,
    singOut,
    loading,
    session,
    setSession,
    validateSession,
    error,
    setError,
    singOutWithoutRedirect,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function ProtectRoute(Component) {
  return () => {
    const { loading, session } = useAuth()
    const router = useRouter()
    const inBeatTime = !!Cookies.get('in-beat-time')

    useEffect(async () => {
      if (!session && !loading) {
        try {
          const data = JSON.parse(Cookies.get('token'))
          api.defaults.headers.authorization = `Bearer ${data.access}`

          await api.post('/api/v1/token/verify/', { token: data.access })
        } catch (error) {
          console.error(error)
          Cookies.remove('token')
          Cookies.remove('impersonate')
          Router.push({
            pathname: '/entrar',
            query: {
              nextUrl: window.location.pathname,
            },
          })
        }
      }
    }, [loading, session])

    if (loading || !session) {
      return null
    }

    if (inBeatTime && router.pathname !== '/ponto-eletronico/bater-ponto' && session?.isCompany) {
      Cookies.remove('token')
      Router.push({
        pathname: '/entrar',
        query: {
          nextUrl: window.location.pathname,
        },
      })
    }

    return (<Component {...arguments} />)
  }
}

export default function useAuth() {
  const context = useContext(AuthContext)

  return context
}
