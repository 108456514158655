import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import useAuth from '../../../context/useAuth'
import LovedProvider from '../../../context/useLoved'
import candidateTheme from './candidate'
import companyTheme from './company'

function getTheme(isCompany) {
  if (isCompany) {
    return companyTheme
  }

  return candidateTheme
}

export default function ThemeProviderMui4({ children }) {
  const { session } = useAuth()
  const theme = getTheme(session?.isCompany)

  return (
    <ThemeProvider theme={theme}>
      <LovedProvider>
        {children}
      </LovedProvider>
    </ThemeProvider>
  )
}
