import { useState } from 'react'
import { service } from 'services'
import Cookies from 'js-cookie'
import useAuth from 'context/useAuth'
import { useRouter } from 'next/router'

export function useCompany() {
  const [company, setCompany] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [opportunity, setOpportunity] = useState({})
  const [modalCandidateOpened, setModalCandidateOpened] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [errorModalOpened, setErrorModalOpened] = useState(false)
  const [successModalOpened, setSuccessModalOpened] = useState(false)
  const { validateSession } = useAuth()
  const router = useRouter()

  function findCompanyBySlug(slug) {
    setLoading(true)

    service
      .company
      .findBySlug(slug)
      .then(setCompany)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  function findCompanyOpportunityBySlug(id) {
    setLoading(true)

    service
      .opportunity
      .findBySlug(id)
      .then(setOpportunity)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  function checkIfExistsPreOpportunityUuid() {
    const preOpportunityUuid = Cookies.get('pre-opportunity-uuid')
    const tracker = Cookies.get('tracker')
    const googleJobs = Cookies.get('google-jobs-origin')
    const { query } = router

    if (preOpportunityUuid) {
      const payload = {
        tracker: tracker || 'slug',
        origin: googleJobs || query?.origin || 'current',
        origin_detail: {
          page: query?.page || 'slug',
        },
      }

      service.company
        .assignCandidateToOpportunity(preOpportunityUuid, payload)
        .then(() => {
          Cookies.set('candidate-slugged', true)
          Cookies.remove('pre-opportunity-uuid')
          Cookies.remove('google-jobs-origin')
        })
        .catch(console.error)
    }
  }

  function registerEmployee(params, images) {
    setLoading(true)
    setSuccessModalOpened(false)
    setErrorModalOpened(false)

    const formData = new FormData()

    formData.append('candidate', params)

    if (images && images.length) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i], images.name)
      }
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    }

    service.registerCandidate.register(requestOptions)
      .then((response) => response.json())
      .then(async (response) => {
        if (response.people) {
          setEmployee(response.people)
          setSuccessModalOpened(true)

          await validateSession({
            access: response.token,
          })

          return router.push('/inicio')
        }

        const key = Object.keys(response)?.length ? Object.keys(response)[0] : null

        throw new Error(response[key] || 'Error')
      })
      .catch((error) => {
        const key = Object.keys(error)?.length ? Object.keys(error)[0] : null

        setError(key ? error[key] : error.message)

        setErrorModalOpened(true)
      })
      .finally(() => setLoading(false))
  }

  function hireEmployee(interviewId, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await service.employee.hireEmployee(interviewId, payload)
        resolve(data)
      } catch (error) {
        reject({
          message: 'Não foi possível criar o employee',
          error,
        })
      }
    })
  }

  function absentCandidate(interviewId, candidateId) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await service.employee.absentCandidate(interviewId, candidateId)
        resolve(data)
      } catch (error) {
        reject({
          message: 'Não foi possível criar o employee',
          error,
        })
      }
    })
  }

  function rejectCandidate(interviewId, candidateId) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await service.employee.rejectCandidate(interviewId, candidateId)
        resolve(data)
      } catch (error) {
        reject({
          message: 'Não foi possível criar o employee',
          error,
        })
      }
    })
  }

  return {
    findCompanyBySlug,
    checkIfExistsPreOpportunityUuid,
    registerEmployee,
    company,
    loading,
    error,
    setError,
    opportunity,
    setOpportunity,
    modalCandidateOpened,
    setModalCandidateOpened,
    employee,
    setEmployee,
    errorModalOpened,
    setErrorModalOpened,
    successModalOpened,
    setSuccessModalOpened,
    hireEmployee,
    absentCandidate,
    rejectCandidate,
    findCompanyOpportunityBySlug,
  }
}
