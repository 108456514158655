import api from "../../providers/api";

async function find(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/job-position/${id}`)

      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível buscar o detalhe dessa posição',
        detail: error
      })
    }
  })
}

async function list(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/job-position/`, { params })

      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as posições disponíveis',
        detail: error
      })
    }
  })
}

export default {
  find,
  list,
}
