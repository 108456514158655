import React, {
  createContext,
  useState,
  useEffect,
  useContext
 } from "react";
import { service } from '../services';

const LovedContext = createContext();

export default function LovedProvider({ children }) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [heartLoved, setHeartLoved] = useState([])

    const getLovedHeart = (companyId) => {
      setLoading(true)

      service.loved
        .getLovedHeart(companyId)
        .then(setHeartLoved)
        .catch(setError)
        .finally(() => setLoading(false))
    }

    const handleLoved = (isLoved, companyId, peopleId) => {
      setLoading(true)

      service.loved
        .setLoved(isLoved, companyId, peopleId)
        .catch(setError)
        .finally(() => setLoading(false))
    }

    useEffect(() => {
      setLoading(false)
    }, [])

  return (
    <LovedContext.Provider
      value={{
        loading,
        handleLoved,
        heartLoved,
      }}>
      {children}
    </LovedContext.Provider>
  )
}

export function useLoved() {
  const context = useContext(LovedContext);
  const {
    loading,
    handleLoved,
    heartLoved
  } = context;

  return {
    loading,
    handleLoved,
    heartLoved
   };
}
