import React from 'react'
import useAuth from 'context/useAuth'
import { ThemeProvider } from '@mui/material/styles'
import * as themes from '@w550/worc-ui/dist/themes'

function getTheme(isCompany) {
  if (isCompany) {
    return themes.company
  }

  return themes.candidate
}

export default function ThemeProviderMui5({ children }) {
  const { session } = useAuth()
  const theme = getTheme(session?.isCompany)

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
