import api from "../../providers/api";

async function list() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/compane-images/`)

      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as imagens',
        detail: error
      })
    }
  })
}

export default {
  list
}
