/* eslint-disable no-console */
import { DateTime } from 'luxon'
import api from '../../providers/api'
import { serializeEvent, serializeEventDetail, serializerInterview } from './serializers'

function find(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/calendar/${id}/`)
      .then(({ data }) => resolve(serializeEventDetail(data)))
      .catch((error) => {
        error.message = 'Não foi possível buscar os dados da agenda'
        console.error(error)

        reject(error)
      })
  })
}

function listEvents(params) {
  return new Promise((resolve, reject) => {
    const payload = {
      ...params,
      address: params.address?.join(',') || '',
    }

    api
      .get('/api/v1/calendar/', { params: payload })
      .then(({ data }) => data.map(serializeEvent))
      .then(resolve)
      .catch((error) => {
        error.message = 'Não foi possível listar os eventos da agenda'
        console.error(error)
        reject(error)
      })
  })
}

function getPastInterviews(companyId) {
  return new Promise((resolve, reject) => {
    const params = {
      start_at__lte: `${DateTime.now().minus({ hours: 1 }).toFormat('yyyy-LL-dd HH:mm:ss')}`,
      empty_candidate: false,
      status: 'accepted',
    }

    api
      .get('/api/v1/calendar/', { params })
      .then(({ data }) => resolve(serializerInterview(data)))
      .catch((error) => {
        error.message = 'Não foi possível listar as entrevistas'
        console.error(error)

        reject(error)
      })
  })
}

async function createEvent(event) {
  return new Promise((resolve, reject) => {
    api
      .post('/api/v1/calendar/', event)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao criar o evento'
        console.error(error)

        reject(error)
      })
  })
}

function update(id, event) {
  return new Promise((resolve, reject) => {
    api
      .patch(`/api/v1/calendar/${id}/`, event)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao atualizar o evento'
        console.error(error)

        reject(error)
      })
  })
}

async function destroy(id) {
  return new Promise(async (resolve, reject) => {
    api
      .delete(`/api/v1/calendar/${id}/`)
      .then(() => resolve({
        message: 'Horário removido com sucesso',
      }))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao deletar o horário'
        console.error(error)

        reject(error)
      })
  })
}

export default {
  find,
  listEvents,
  createEvent,
  update,
  destroy,
  getPastInterviews,
}
