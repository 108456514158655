import Router from 'next/router'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import config from 'config/environment'

export function initGtm() {
  if (config.google.googleAnalytics) {
    ReactGA.initialize(config.google.googleAnalytics)
    ReactGA.pageview(Router.router.asPath)
  }
}

export function initGA() {
  if (config.google.gtmId) {
    TagManager.initialize({
      gtmId: config.google.gtmId,
    })
  }
}
