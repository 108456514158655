import {
  hotjar,
} from 'react-hotjar'
import config from 'config/environment'

export function initHotjar() {
  if (config.hotjar.userId) {
    hotjar.initialize(
      config.hotjar.userId,
      config.hotjar.channelId,
    )
  }
}
