/* eslint-disable no-console */
import { serializeModule } from 'services/people/module-serializer'
import api from '../../providers/api'

const opportunityTypes = {
  clt: 'CLT',
  clt_flex: 'CLT Flex',
  freelancer: 'Freelancer',
}

function serializeJobOpportunity(opportunity) {
  return {
    ...opportunity,
    type_opportunity_description: opportunityTypes[opportunity.type_opportunity] ?? false,
    salary: parseFloat(opportunity.salary),
    salary_by_hour: parseFloat(opportunity.salary_by_hour),
  }
}

function serializePositions(responsePositions) {
  return responsePositions.map(({ data }) => data || {})
}

async function list(params = {}) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/job-opportunity-company/', { params })
      .then(({ data }) => resolve({
        ...data,
        results: data.results.map(serializeJobOpportunity),
      }))
      .catch((error) => {
        error.message = 'Não possível listar as vagas disponíveis'

        if (error.response?.status === 404) {
          error.message = 'Nenhuma vaga encontrada pra esse filtro'
        }

        reject(error)
      })
  })
}

async function listByCandidate(params = {}) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/job-opportunity/jobs/', { params })
      .then(({ data }) => resolve({
        ...data,
        results: data.results.map(serializeJobOpportunity),
      }))
      .catch((error) => {
        error.message = 'Não possível listar as vagas disponíveis'

        if (error.response?.status === 404) {
          error.message = 'Nenhuma vaga encontrada pra esse filtro'
        }

        if (error.response?.status === 403) {
          error.message = 'Você não tem permissão para executar essa ação.'
        }

        reject(error)
      })
  })
}

async function listFiltered(people_id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: opportunities } = await api.get(`/api/v1/job-opportunity-company/?people_id=${people_id}`)

      resolve(opportunities.map(serializeJobOpportunity))
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível listar as vagas disponíveis',
        detail: error,
      })
    }
  })
}

async function find(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: opportunity } = await api.get(`/api/v1/job-opportunity-company/${id}/`)

      const { data: position } = await api.get(`/api/v1/job-position/${opportunity.position.id}/`)

      const periodWorkType = opportunity.period_job.find((period) => period.time_type === 'work') || {}
      const periodRestType = opportunity.period_job.find((period) => period.time_type === 'rest') || {}

      opportunity.work_start = periodWorkType.start_time || ''
      opportunity.work_end = periodWorkType.end_time || ''
      opportunity.rest_start = periodRestType.start_time || ''
      opportunity.rest_end = periodRestType.end_time || ''

      opportunity.position = position

      resolve(serializeJobOpportunity(opportunity))
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível buscar os dados da vaga',
        detail: error,
      })
    }
  })
}

async function findByCandidate(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: opportunity } = await api.get(`/api/v1/job-opportunity/job/${id}/`)

      const periodWorkType = opportunity.period_job.find((period) => period.time_type === 'work') || {}
      const periodRestType = opportunity.period_job.find((period) => period.time_type === 'rest') || {}

      opportunity.work_start = periodWorkType.start_time || ''
      opportunity.work_end = periodWorkType.end_time || ''
      opportunity.rest_start = periodRestType.start_time || ''
      opportunity.rest_end = periodRestType.end_time || ''

      resolve(serializeJobOpportunity(opportunity))
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível buscar os dados da vaga',
        detail: error,
      })
    }
  })
}

async function findBySlug(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: opportunity } = await api.get(`/api/v1/job-opportunity/${id}/`)

      const periodWorkType = opportunity.period_job.find((period) => period.time_type === 'work') || {}
      const periodRestType = opportunity.period_job.find((period) => period.time_type === 'rest') || {}

      opportunity.work_start = periodWorkType.start_time || ''
      opportunity.work_end = periodWorkType.end_time || ''
      opportunity.rest_start = periodRestType.start_time || ''
      opportunity.rest_end = periodRestType.end_time || ''

      resolve(serializeJobOpportunity(opportunity))
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível buscar os dados da vaga',
        detail: error,
      })
    }
  })
}

async function create(body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api
        .post('/api/v1/job-opportunity-company/', body)

      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível criar essa vaga',
        detail: error,
      })
    }
  })
}

async function update(id, body) {
  return new Promise((resolve, reject) => {
    api
      .patch(`/api/v1/job-opportunity-company/${id}/`, body)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao atualizar a vaga'

        console.error(error)
        reject(error)
      })
  })
}

async function hotList(opportunityId, params = {}) {
  return new Promise((resolve, reject) => {
    params.sort = '-healthscore'

    api
      .get(`/api/v1/job-opportunity-company/${opportunityId}/hotlist/`, { params })
      .then(({ data }) => {
        data.results.forEach((people) => {
          if (people.modules?.length) {
            people.modules = people?.modules.map(serializeModule)
          }
        })

        resolve(data)
      })
      .catch((error) => {
        error.message = 'Ocorreu um erro ao listar'
        reject(error)
      })
  })
}

/**
 * Returns if user is registered as a candidate in the opportunity
 * @param {opportunity} opportunity
 * @param {profile} profile
 *
 * @returns {candidate}
 */
async function findCandidate(opportunity, profile) {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        opportunity_id: opportunity,
        candidate_id: profile,
      }

      const { data } = await api.get('/api/v1/job-candidate/', { params })

      if (data.length) {
        resolve(data[0])
      }

      resolve(null)
    } catch (error) {
      reject({
        message: 'Ocorreu um erro ao listar',
        detail: error,
      })
    }
  })
}

/**
 * Returns all opportunities cleared
 *
 * @returns {opportunities}
 */
async function clt() {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/job-opportunity-company/short/?type_opportunity=clt')
      .then(({ data }) => resolve(data))
      .catch(reject)
  })
}

/**
 * List opportunities by feed
 * @param {number} params.company
 * @param {number} params.positions
 *
 * @returns {Promise}
 */
async function opportunityBoard(params) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/job-opportunity/jobs/', { params })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar as vagas'

        reject(error)
      })
  })
}

/**
 * List opportunities average wage
 * @param {number} position_id
 * @param {string} zipcode
 * @returns {Promise}
 */
async function averageWage(position_id, zipcode) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/job-opportunity/${position_id}/${zipcode}/average_wage/` )
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar as vagas'
        reject(error)
      })
  })
}

export default {
  list,
  listByCandidate,
  listFiltered,
  find,
  findByCandidate,
  create,
  update,
  hotList,
  findCandidate,
  clt,
  findBySlug,
  opportunityBoard,
  averageWage,
}
