/* eslint-disable no-console */
import api from 'providers/api'
import { callToInteviewValidate } from './validators'
import modules from './modules'

function list(params) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/job-candidate/', { params })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar os candidato'

        reject(error)
      })
  })
}

function callInterview(payload) {
  return new Promise((resolve, reject) => {
    const isValidPayload = callToInteviewValidate(payload)

    if (!isValidPayload) {
      const error = new Error('Dados incosistentes para chamar o candidato para entrevista.')

      console.error(error)

      return reject(error)
    }

    api
      .post('/api/v1/job-candidate/', payload)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.messages = ['Não foi possível chamar o candidato para entrevista']

        if ([400, 500].includes(error.response?.status)) {
          error.validatorError = true
        }

        if (error.response?.data && error.response?.status === 400) {
          error.messages = Object
            .keys(error.response?.data)
            .map((key) => error.response?.data[key][0])
        }

        error.message = error.messages.join(',').replaceAll(',', '. ')

        console.error(error)

        reject({
          message: error.message,
          error,
        })
      })
  })
}

export default {
  list,
  callInterview,
  modules,
}
