import Axios from 'axios'

import config from '../config/environment/index'

const api = Axios.create({
  baseURL: config.apis.host,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

export default api
