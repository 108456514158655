import api from '../../providers/api'

async function syncCandidates(companyId, jobId, filters, link) {
  const url = link || `api/v1/select-process/company/${companyId}/opportunity/${jobId}/?filters=${filters}&page=1&page_size=7`
  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(({ data }) => {
        data.candidates = data?.platform
        resolve(data)
      })
      .catch((error) => {
        error.message = 'Não possível listar os cadidatos para essa vaga'

        reject(error)
      })
  })
}

async function getJobs(param) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/job-list/company/${param}`)
      .then(({ data: { data } }) => resolve(data))
      .catch((error) => {
        error.message = 'Não possível listar as vagas'
        reject(error)
      })
  })
}

async function includeSeletiveProcess(jobId, candidates, status = 'wait') {
  return new Promise((resolve, reject) => {
    api
      .post('/api/v1/select-process-company/apply-candidate/', {
        opportunity: jobId,
        candidates,
        status,
      })
      .then(({ data }) => resolve(data))
      .catch(reject)
  })
}

async function callAll(candidates) {
  return new Promise((resolve, reject) => {
    api
      .post('/api/v1/select-process-company/call-candidate/', {
        candidates,
      })
      .then(({ data }) => resolve(data))
      .catch(reject)
  })
}

export default {
  syncCandidates,
  getJobs,
  includeSeletiveProcess,
  callAll,
}
