import Axios from 'axios'

async function getCep(cep) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.get(`https://viacep.com.br/ws/${cep}/json`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar o cep',
        detail: error,
      })
    }
  })
}

export default {
  getCep
}
