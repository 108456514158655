/* eslint-disable no-console */
import api from 'providers/api'
import { serializeScale, serializeTimeTrackingReport } from 'services/timetracking/serializer'

function list(employeeUuid, params) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/workschedule/${employeeUuid}/report/`, { params })
      .then(({ data }) => resolve(serializeTimeTrackingReport(data)))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao listar o horários'

        reject(error)
      })
  })
}

async function create(cpf, image, subsidiary, geolocation = '') {
  try {
    const formData = new FormData()

    formData.append('cpf', cpf)
    formData.append('image', image)
    formData.append('subsidiary', subsidiary)
    formData.append('geolocation', geolocation)

    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const { data } = await api.post('/api/v1/timetracking/', formData, requestConfig)

    return Promise.resolve(data)
  } catch (error) {
    error.messages = ['Ocorreu um erro ao bater o ponto']

    if ([400, 500].includes(error.response?.status)) {
      error.validatorError = true
    }

    if (error.response?.data && error.response?.status === 400) {
      error.messages = Object
        .keys(error.response?.data)
        .map((key) => error.response?.data[key])
    }

    return Promise.reject(error)
  }
}

async function edit(
  id,
  time,
  justification,
  justificationText = '',
  pointType = '',
  employee = '',
  subsidiary = '',
  day = '',
) {
  try {
    const formData = {
      to_clock: time,
      justification,
      reason_for_edit: justificationText,
      point_type: (!id && pointType) ? pointType : undefined,
      day: (!id && day) ? day : undefined,
      employee: (!id && employee) ? employee : undefined,
      subsidiary: (!id && subsidiary) ? subsidiary : undefined,
    }

    if (id) {
      const { data } = await api.patch(`/api/v1/timetracking/${id}/`, formData)
      return Promise.resolve(data)
    }

    const { data } = await api.post('/api/v1/timetracking/add/', formData)
    return Promise.resolve(data)
  } catch (error) {
    error.messages = ['Ocorreu um erro ao bater o ponto']

    if ([400, 500].includes(error.response?.status)) {
      error.validatorError = true
    }

    if (error.response?.data && error.response?.status === 400) {
      error.messages = Object
        .keys(error.response?.data)
        .map((key) => error.response?.data[key][0])
    }

    return Promise.reject(error)
  }
}

async function getScale(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/workschedule/${id}/`)
      resolve(serializeScale(data))
    } catch (error) {
      reject({
        message: 'Não foi possível listar os dados',
        detail: error,
      })
    }
  })
}

async function listScales(name) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/workschedule/?is_active=true&name=${name || ''}`)
      const { data: dataInactive } = await api.get(`/api/v1/workschedule/?is_active=false&name=${name || ''}`)
      resolve({ active: data, inactive: dataInactive })
    } catch (error) {
      reject({
        message: 'Não foi possível listar os dados',
        detail: error,
      })
    }
  })
}

async function listScalesByDays(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get('/api/v1/workschedule/by_day/', { params })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível listar os dados',
        detail: error,
      })
    }
  })
}

async function saveScale(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post('/api/v1/workschedule/', payload)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível salvar os dados',
        detail: error,
      })
    }
  })
}

async function deleteScale(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.delete(`/api/v1/workschedule/${id}/`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível salvar os dados',
        detail: error,
      })
    }
  })
}

async function activeInactiveScale(id, newState) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.patch(`/api/v1/workschedule/${id}/`, {
        is_active: newState,
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível salvar os dados',
        detail: error,
      })
    }
  })
}

async function getBigNumbersSubsidiary(id, startDate, endDate, scaleType) {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        subsidiary: id,
        start_date: startDate,
        end_date: endDate,
        scale_type: scaleType || undefined,
      }

      const { data } = await api.get('/api/v1/timetracking/big_numbers/', { params })

      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível salvar os dados',
        detail: error,
      })
    }
  })
}

async function getBigNumbersEmployee(id, startDate, endDate, scaleType) {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        employee: id,
        start_date: startDate,
        end_date: endDate,
        scale_type: scaleType || undefined,
      }

      const { data } = await api.get('/api/v1/timetracking/big_numbers/', { params })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível salvar os dados',
        detail: error,
      })
    }
  })
}

async function getTimesEmployees(employee, subsidiary, startDate, endDate, scaleType = '', page = 1, page_size = 7, getBigNumbers = true) {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        employee,
        subsidiary,
        start_date: startDate,
        end_date: endDate,
        scale_type: scaleType || undefined,
        page,
        page_size,
      }

      const { data: { results: times, count, total_pages } } = await api.get('/api/v1/timetracking/', { params })

      if (!getBigNumbers) {
        return resolve({
          times,
          count,
          total_pages,
        })
      }

      delete params.page
      delete params.page_size

      const { data: bigNumbers } = await api.get('/api/v1/timetracking/big_numbers/', { params })

      const paload = {
        times,
        bigNumbers,
        count,
        total_pages,
      }
      resolve(paload)
    } catch (error) {
      reject({
        message: 'Não foi possível salvar os dados',
        detail: error,
      })
    }
  })
}

export default {
  list,
  create,
  edit,
  getScale,
  listScales,
  listScalesByDays,
  saveScale,
  activeInactiveScale,
  deleteScale,
  getTimesEmployees,
  getBigNumbersSubsidiary,
  getBigNumbersEmployee,
}
