import * as randomColor from 'randomcolor'

function companySerializer(company) {
  return {
    ...company,
    id: company.id,
    available_opportunities: company.available_opportunities,
    addresses: company.addresses?.filter(address => address.name) || [],
    logo: company.logo,
    address_main: company.addresses?.find(address => address.is_main && address.name) || company.addresses[0] || {},
  }
}

function candidateSerializer(candidate) {
  const {
    healthscore,
    employees,
  } = candidate

  const colors = randomColor({
    luminosity: 'dark',
    format: 'rgba',
    alpha: 0.4,
    count: employees?.length || 0,
    hue: 'random',
  })

  const employeesColors = employees
    .map((e, i) => ({ ...e, color: colors[i] }))

  return {
    ...candidate,
    healthscore: Number(healthscore || 0).toFixed(0),
    employees: employeesColors,
  }
}

export function profileSerializer(profile) {
  const data = {
    email: profile.user.email || 'Não informado',
    isCompany: profile.type === 'company',
  }

  if (profile.type === 'company') {
    data.company = companySerializer(profile.info)

    data.name = profile.info?.nome_fantasia || profile.info?.razao_social || 'Não informado'
    data.avatar = profile.info?.logo
  }

  if (profile.type === 'people') {
    data.candidate = candidateSerializer(profile.info)
    data.name = profile.info?.name || 'Não informado'
    data.avatar = profile.info?.avatar
  }

  return data
}
