import api from 'providers/api'

export function calculateBetweenTwoAddress(address1, address2) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/distance/origin/${address1}/destination/${address2}/`)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao buscar a distância'
        reject(error)
      })
  })
}

export default {
  calculateBetweenTwoAddress,
}
