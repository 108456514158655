import React, {
  createContext, useContext, useState,
} from 'react'
import PropTypes from 'prop-types'

export const SnackbarContext = createContext({
  updateSnackbar: () => {},
  snackbar: false,
})

const initialState = {
  message: '',
  open: false,
  autoHideDuration: 6000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState(initialState)

  function updateSnackbar(options) {
    if (!options.open) {
      setSnackbar(initialState)
    } else {
      setSnackbar({
        ...snackbar,
        ...options,
        anchorOrigin: {
          ...snackbar.anchorOrigin,
          ...options?.anchorOrigin,
        },
      })
    }
  }

  const value = {
    snackbar,
    updateSnackbar,
  }

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function useSnackbar() {
  const {
    updateSnackbar,
    snackbar,
  } = useContext(SnackbarContext)

  return {
    updateSnackbar,
    snackbar,
  }
}
