import api from 'providers/api'

/**
 * List modules of profile
 *
 * @param {number} profile
 * @returns {array}
 */
function list() {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/exam/')
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar as provas disponíveis'
        reject(error)
      })
  })
}

/**
 * List exams of opportunity
 *
 * @param {id} opportunity
 * @returns {array}
 */
function listByOpportunity(opportunity) {
  return new Promise(
    (resolve, reject) => api
      .get(`/api/v1/job-opportunity/job/${opportunity}/tests/`)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível listar os exames'
        reject(error)
      }),
  )
}

/**
 * Find on module of profile
 *
 * @param {number} profile
 * @param {number} module
 * @returns {array}
 */
function find(module, params) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/exam/${module}/`, {params})
      .then(({data}) => resolve(data))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        error.message = 'Não foi possível encontrar esse módulo'

        reject(error)
      })
  })
}

/**
 * List questions of a module of profile
 *
 * @param {number} profile
 * @param {number} module
 * @returns {object}
 */
function questions(module) {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/v1/exam-test/start/${module}/`)
      .then(({data}) => {
        if (!data.questions?.length) {
          const error = new Error()
          error.message = 'Essa prova ainda não possui questões para responder.'

          reject(error)
        }

        data.questions.forEach((question) => {
          question.seconds = question.seconds
            ? parseInt(question.seconds, 10)
            : 60
        })

        resolve(data.questions)
      })
      .catch((error) => {
        error.message = 'Não foi possível buscar as questões desse módulo'
        // eslint-disable-next-line no-console
        console.error(error)
        reject(error)
      })
  })
}

/**
 * answer a question
 * @param {number} questionId
 * @param {number} answer
 * @returns
 */
function askQuestion(questionId, answer) {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/v1/exam-test/answer/${questionId}/`, {answer})
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível atualizar a resposta dessa questão'
        reject(error)
      })
  })
}

/**
 * Finish an exam
 *
 * @param {number} module
 * @returns
 */
function finishExam(module) {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/v1/exam-test/finish/${module}/`)
      .then(({data}) => resolve(data))
      .catch((error) => {
        error.message = 'Não foi possível finalziar o exame'
        reject(error)
      })
  })
}

export default {
  list,
  find,
  questions,
  askQuestion,
  finishExam,
  listByOpportunity,
}
