import config from 'config/environment/index'
import api from "providers/api"

const register = (requestOptions) => (
  fetch(`${config.apis.host}/api/v1/people/`, requestOptions)
)

const edit = (requestOptions) => (
  fetch(`${config.apis.host}/api/v1/people/`, requestOptions)
)

async function checkIsValidEmail(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`api/v1/people/validate-cpf-email/?email=${encodeURIComponent(email)}`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'não foi possivel consultar o email',
        detail: error,
        status: error.request.status,
        showAlert: true
      })
    }
  })
}

async function checkIsValidCpf(cpf) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`api/v1/people/validate-cpf-email/?cpf=${cpf}`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'o cpf já existe',
        detail: error,
        status: error.request.status,
        showAlert: true
      })
    }
  })
}

async function checkUserExist(body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post('/api/v1/social/auth/', body)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Ocorreu um erro',
        detail: error,
        status: error.request.status,
        showAlert: true
      })
    }
  })
}

export default {
  register,
  edit,
  checkIsValidEmail,
  checkIsValidCpf,
  checkUserExist,
}
