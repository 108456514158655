/* eslint-disable no-console */
import api from '../../providers/api'

/**
 * Returns serialized company data
 * @param company Object
 *
 * @returns company
 */
function serializeCompany(company) {
  return {
    ...company,
  }
}

function serializeProfileCompany(company, opportunityId) {
  const opportunity = company.opportunity.find(({ id }) => id === parseInt(opportunityId, 10))

  const addresses = company.opportunity.reduce((prev, curr) => {
    prev.push(curr.address)
    return prev
  }, [])

  const address = opportunity?.address || addresses[0] || {}

  return {
    ...company,
    addresses,
    address: {
      ...address,
      candidate: {},
    },
  }
}

/**
 * Returns company data
 * @param slug String
 *
 * @returns Promise<company>
 */
async function findBySlug(slug) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: company } = await api.get(`/api/v1/company/${slug}/`)
      resolve(serializeCompany(company))
    } catch (error) {
      console.error(error)
      reject({
        message: 'Não possível buscar os dados da empresa',
        detail: error,
      })
    }
  })
}

/**
 * Assign logged candidate to company opportunity
 *
 * @param {uuid} opportunityUuid
 * @param {object} payload
 * @param {string} payload.tracker
 * @returns {Promise}
 */
async function assignCandidateToOpportunity(opportunityUuid, payload) {
  return new Promise((resolve, reject) => {
    const decodedTracker = decodeURIComponent(payload.tracker)
    payload.tracker = decodedTracker

    api
      .post(`/api/v1/job-candidate/${opportunityUuid}/apply-opportunity/`, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Não possível cadastrar o candidato na vaga escolhida'
        reject(error)
      })
  })
}

/**
 *
 * @param {number} id
 *
 * @returns {Promise}
 */
function profile(companyId, opportunityId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/company/${companyId}/profile/`)
      .then(({ data }) => resolve(serializeProfileCompany(data, opportunityId)))
      .catch((error) => {
        error.message = 'Não foi possível buscar os dados da empresa'
        reject(error)
      })
  })
}

/**
 * List employees by logged company
 *
 * @returns Promise>
 */
function employees(params = {}) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/employee/', { params })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        console.error(error)
        reject(error)
      })
  })
}

export default {
  findBySlug,
  assignCandidateToOpportunity,
  employees,
  profile,
}
