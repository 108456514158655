import api from "../../providers/api";

async function recoveryEmail(doc) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(`/api/v1/users/email-forbidden/`, {
        document: doc
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi possível listar emails',
        detail: error
      })
    }
  })
}

async function changeEmail(newEmail) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(`/api/v1/users/change-email/`, {
        email: newEmail
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não foi alterar email',
        detail: error
      })
    }
  })
}

export default {
  recoveryEmail,
  changeEmail
}
