// import ReactPixel from 'react-facebook-pixel'
import Router from 'next/router'
import config from 'config/environment'

export function initFBPixel() {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.init(config.facebook.pixelId)
      ReactPixel.pageView()

      Router.events.on('routeChangeComplete', () => {
        ReactPixel.pageView()
      })
    })
}
