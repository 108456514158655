import api from 'providers/api'

function list(positionUuid) {
  return new Promise(async (resolve, reject) => {
    try {
      const {data} = await api.get(`/api/v1/exam/${positionUuid}/modules/`)

      resolve(data)
    } catch (error) {
      reject({
        message: 'Ocorreu um erro ao listar os módulos',
        detail: error
      })
    }
  })
}

export default {
  list
}
