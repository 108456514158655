import qs from 'qs'
import api from '../../providers/api'
import { serializeTechnicalModules } from './module-serializer'
import { serializeCandidate } from './people-serializer'

export function list(params = {}) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/people/', {
        params,
        paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'comma' }),
      })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao buscar candidatos'
        reject(error)
      })
  })
}

export function find(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/people/${id}/`)
      .then(({ data }) => serializeCandidate(data))
      .then(resolve)
      .catch((error) => {
        error.message = 'Ocorreu um erro buscar os dados do usuário'
        reject(error)
      })
  })
}

export function curriculum(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/people/${id}/curriculum/`)
      .then(({ data }) => data)
      .then(resolve)
      .catch((error) => {
        error.message = 'Ocorreu um erro buscar os dados do usuário'
        reject(error)
      })
  })
}

export function professionalProfile(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/professional-profile/${id}/`)

      const modules = {
        professional: [],
        technical: [],
      }
      const professionalModule = data.modules.find((module) => module.kind_of_questions === 'P')

      if (professionalModule) {
        const resultTypes = {
          conduct: 'Conduta',
          comunication: 'Comunicação',
          profile: 'Resultados',
        }

        modules.professional = Object
          .keys(professionalModule.result)
          .map((key) => ({
            ...professionalModule.result[key],
            description: professionalModule.result[key]?.description?.replaceAll('\r\n', '<br />'),
            type: resultTypes[key],
          }))
      }

      const technicalModules = data.modules.filter((module) => module.kind_of_questions === 'T')

      if (technicalModules.length) {
        modules.technical = serializeTechnicalModules(technicalModules)
      }

      modules.phone = data.phone

      resolve(modules)
    } catch (error) {
      console.error(error)
      reject({
        message: 'Ocorreu um erro buscar os dados do usuário',
        detail: error,
      })
    }
  })
}

export function update(id, body) {
  return api
    .patch('/api/v1/people/', body)
}

export function updateAvailability(body) {
  return api
    .patch('/api/v1/people/availability/', body)
}

export function listUnavailabilityReasons() {
  return new Promise((resolve, reject) => {
    api.get('/api/v1/people/unavailability-reasons')
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Ocorreu um erro ao buscar as reasons'
        reject(error)
      })
  })
}

export function updateProfileReason(body) {
  return api
    .patch('/api/v1/people/availability/', body)
}

export function availableToWorkProfile(body) {
  return api
    .post('/api/v1/people/availability/', body)
}

export default {
  list,
  find,
  professionalProfile,
  update,
  listUnavailabilityReasons,
  updateProfileReason,
  availableToWorkProfile,
  curriculum,
}
