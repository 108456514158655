/* eslint-disable no-console */
import api from 'providers/api'

async function getFreelancer(subsidiary = null, params = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(
        `/api/v1/employee/?job_type=freelancer&status=accepted,requested${subsidiary ? `&subsidiary=${subsidiary}` : ''}&pagination=true&page=${params.page ? params.page : '1'}&page_size=3`,
      )
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function getCltFlex(subsidiary = null, params = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/employee/?job_type=clt_flex${subsidiary ? `&subsidiary=${subsidiary}` : ''}&pagination=true&page=${params.page ? params.page : '1'}&page_size=15`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function getClt(subsidiary = null, params = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/employee/?job_type=clt&status=accepted,requested${subsidiary ? `&subsidiary=${subsidiary}` : ''}&pagination=true&page=${params.page ? params.page : '1'}&page_size=15`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function getCltAccepted(subsidiary = null) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/employee/?job_type=clt&status=accepted${subsidiary ? `&subsidiary=${subsidiary}` : ''}`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function getEmployeesAccepted(subsidiary = null) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/employee/?status=accepted${subsidiary ? `&subsidiary=${subsidiary}` : ''}`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function getFavorite(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get('/api/v1/loved-list/')
      .then(({ data: { data } }) => resolve(data))
      .catch((error) => {
        error.message = 'Não possível buscar os favoritos'

        console.error(error)
        reject(error)
      })
  })
}

async function getSuggested(companyId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: { data } } = await api.get('/api/v1/suggested-list/')
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function changeEmployeeCompanyStatus(employeeId, status) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.patch(`/api/v1/employee/${employeeId}/`, { status })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

async function removeEmployee(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.delete(`/api/v1/employee/${employeeId}/`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar os cadidatos para essa vaga',
        detail: error,
      })
    }
  })
}

export default {
  getFreelancer,
  getCltFlex,
  getClt,
  getFavorite,
  getSuggested,
  changeEmployeeCompanyStatus,
  removeEmployee,
  getCltAccepted,
  getEmployeesAccepted,
}
