/* eslint-disable no-console */
import api from '../../providers/api'

async function getInterview(peopleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`api/v1/job-candidate/?candidate_id=${peopleId}&status=sent`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as entrevistas',
        detail: error,
      })
    }
  })
}

async function getNextInterview(peopleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: { data } } = await api.get('api/v1/interview/?filter=next_interview')
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as entrevistas',
        detail: error,
      })
    }
  })
}

async function getAcceptedInterview(peopleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: { data } } = await api.get('api/v1/interview/?filter=accepted')
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as entrevistas',
        detail: error,
      })
    }
  })
}

async function getMakedInterview(peopleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: { data } } = await api.get('api/v1/interview/?filter=finished_interview')
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar as entrevistas',
        detail: error,
      })
    }
  })
}

async function acceptInterview(idCandidate, momentId, status) {
  return new Promise(async (resolve, reject) => {
    api
      .post(`/api/v1/job-candidate/${idCandidate}/confirm-status-interview/`, {
        status,
        moment_id: momentId,
      })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        error.message = 'Não possível se candidatar'
        console.error(error)

        reject(error)
      })
  })
}

export default {
  getInterview,
  getNextInterview,
  getMakedInterview,
  acceptInterview,
  getAcceptedInterview,
}
