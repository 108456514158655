import api from "../../providers/api";

async function recoveryPassword(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(`/api/v1/users/password-reset/`, {
        email
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não possível listar emails',
        detail: error
      })
    }
  })
}

async function checkToken(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/users/${token}/check-token/`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'tempo limite expirado',
        detail: error
      })
    }
  })
}

async function newPassword(token, password) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(`/api/v1/users/password-confirm/`, {
        token,
        password
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não salvar a nova senha',
        detail: error
      })
    }
  })
}

async function changePassword(old_password, new_password) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(`/api/v1/users/change-password/`, {
        old_password,
        new_password
      })
      resolve(data)
    } catch (error) {
      reject({
        message: 'Não salvar a nova senha',
        detail: error
      })
    }
  })
}

async function checkIsSocialAuth(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/api/v1/people/is-social-auth/?email=${email}`)
      resolve(data)
    } catch (error) {
      reject({
        message: 'Ocorreu um erro',
        detail: error
      })
    }
  })
}

export default {
  recoveryPassword,
  checkToken,
  newPassword,
  changePassword,
  checkIsSocialAuth,
}
