export default {
  isProduction: process.env.VERCEL_ENV === 'production',
  env: process.env.VERCEL_ENV,
  facebook: {
    pixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL,
  },
  google: {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    googleAnalytics: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    googleLoginClientId: process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID,
  },
  hotjar: {
    userId: process.env.NEXT_PUBLIC_HOTJAR_UDER_ID,
    channelId: process.env.NEXT_PUBLIC_HOTJAR_CHANNEL_ID,
  },
  apis: {
    host: process.env.NEXT_PUBLIC_API_URL,
  },
  config: {
    maxPointsQuestionModule: process.env.NEXT_CONFIG_MAX_POINTS_QUESTION_MODULE || 50,
  },
}
