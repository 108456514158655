import _ from 'lodash'
import api from 'providers/api'

function wageClaim() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/wage-claim/')
      .then(({ data }) => {
        const result = Object
          .keys(data)
          .map((key) => ({
            value: key,
            text: data[key],
          }))

        resolve(result)
      })
  })
}

function schooling() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/schooling/')
      .then(({ data }) => {
        const result = Object
          .keys(data)
          .map((key) => ({
            value: key,
            text: data[key],
          }))

        resolve(result)
      })
  })
}

function cities() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/address/cities/')
      .then(({ data }) => {
        const result = _.uniq(data.map((city) => city[0]).filter(Boolean))

        resolve(result)
      })
  })
}

function technicalAttributes() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/technical-attributes/')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function foodTypes() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/food-type/')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function restaurantTypes() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/restaurant-type/')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function systemTypes() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/system-type/')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function positions() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/job-position/')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function specializations(positionId = null) {
  return new Promise((resolve) => {
    api
      .get(`/api/v1/job-specialization/${positionId ? '?positions=' + positionId : ''}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function sectors() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/sector')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

function courses() {
  return new Promise((resolve) => {
    api
      .get('/api/v1/course/job-opportunity/')
      .then(({ data }) => {
        resolve(data)
      })
      .catch(() => resolve([]))
  })
}

export default {
  wageClaim,
  schooling,
  cities,
  technicalAttributes,
  foodTypes,
  restaurantTypes,
  systemTypes,
  positions,
  specializations,
  sectors,
  courses,
}
