/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import Head from 'next/head'
import { AuthProvider } from 'context/useAuth'
import { SnackbarProvider } from 'context/useSnackbar'

import { initGA, initGtm } from 'providers/google'
import { initFBPixel } from 'providers/facebook'
import { initHotjar } from 'providers/hotjar'
import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '../styles/globas.css'
import '../styles/styles.scss'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import 'react-big-calendar/lib/sass/styles.scss'
import { DefaultLoadingProvider } from 'context/useDefaultLoading'
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className'
import ThemeProviderMui4 from 'providers/theme/mui4'
import ThemeProviderMui5 from 'providers/theme/mui5'

ClassNameGenerator.configure((componentName) => `Mui5-${componentName}`)

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    initGA()
    initGtm()
    initFBPixel()
    initHotjar()
  }, [])

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    window.fbAsyncInit = function () {
      FB.init({
        appId: '5238523616166971',
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      })

      FB.AppEvents.logPageView()
    };

    (function (d, s, id) {
      let js
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  }, [])

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <title>App Worc</title>
      </Head>
      <AuthProvider>
        <ThemeProviderMui4>
          <ThemeProviderMui5>
            <SnackbarProvider>
              <DefaultLoadingProvider>
                <Component {...pageProps} />
              </DefaultLoadingProvider>
            </SnackbarProvider>
          </ThemeProviderMui5>
        </ThemeProviderMui4>
      </AuthProvider>
    </>
  )
}

export default MyApp
