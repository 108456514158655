import moment from 'moment'

export function momentToBr(date) {
  return moment(date).format('DD/MM/YYYY')
}

export function extractHours(dateTime, split = ' ', slice = 5) {
  const [, time] = dateTime.split(split)
  return time.slice(0, slice)
}
