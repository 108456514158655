import { createTheme } from '@material-ui/core/styles';

const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values,
  up: (key) => `@media (min-width:${values[key]}px)`,
};

const overrides = {
  MuiButton: {
    root: {
      borderRadius: '10px',
      textTransform: 'none',
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: '12px',
      '@media(max-width: 600px)': {
        borderRadius: '0px',
      },
    },
  },
};

const companyTheme = createTheme({
  palette: {
    backgroungGlobal: {
      main: '#0b2c46',
    },
    primary: {
      main: '#0b2c46',
      light: '#41C0F0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#41C0F0',
      light: '#0b2c46',
      contrastText: '#ffffff',
    },
    success: {
      main: '#1FE390',
      dark: '#10c87a',
      contrastText: '#ffffff',
    },
    blue: {
      500: '#35BFEE',
    },
    grey: {
      400: '#BFC7CF',
      500: '#AAB4BE',
      600: '#6F7E8C',
    },
  },
  typography: {
    fontFamily: [
      'Exo',
      'Roboyo',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides,
  breakpoints,
});

export default companyTheme;
