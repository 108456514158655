import config from 'config/environment/index'

const register = (requestOptions) => (
  fetch(`${config.apis.host}/api/v1/company/`, requestOptions)
)

const edit = (requestOptions) => (
  fetch(`${config.apis.host}/api/v1/company/`, requestOptions)
)

export default {
  register,
  edit,
}
